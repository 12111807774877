import React, { FC } from "react"
import { graphql, PageProps } from "gatsby";
import { BaseLayout } from "@layouts";
import { GalleryLightbox } from "@components";
import { TypeImage } from "@types";
import { createLabel } from "@utilities";

type GalleryPageData = {
    allFile: {
        images: TypeImage[]
    }
}
type GalleryPageContext = {
    slug: string
}

const GalleryPage: FC<PageProps<GalleryPageData, GalleryPageContext>> = ( { data, pageContext } ) => {
    return (
        <BaseLayout title={ createLabel( pageContext.slug ) } className={ "container" }>
            <h1 className={ "sr-only" }>{ createLabel( pageContext.slug ) }</h1>
            <GalleryLightbox images={ data.allFile.images }/>
        </BaseLayout>
    )
}

export default GalleryPage

export const query = graphql`
    query ($slug: String!){
        allFile(
            sort: {fields: [base]}
            filter: {extension: {regex: "/jpeg|jpg|png|gif/"}, relativeDirectory: {eq: $slug}}
        ) {
            images: nodes {
                relativePath
                childImageSharp {
                    original {
                        src
                    }
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    }
`
